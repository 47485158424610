.withdraw-claim-schedule-modal .ant-modal {
    padding-bottom: 0;
}

.withdraw-claim-schedule-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
    max-height: 386rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.withdraw-claim-schedule-modal .ant-modal-body::-webkit-scrollbar {
    background-color: #202429;
    width: 7.5rem;
}

.withdraw-claim-schedule-modal .ant-modal-body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to top, #f00, #af0000);
}

.withdraw-claim-schedule-modal .ant-modal-body .body-button {
    padding: 0 15px 20px 15px;
}

.withdraw-claim-schedule-modal .ant-modal-content .ant-modal-close {
    top: -30px;
    right: -30px;
}

.withdraw-claim-schedule-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../assets/svg/closeButton.svg') no-repeat center / cover;
    width: 30px;
    height: 30px;
}

.withdraw-claim-schedule-modal .ant-modal-content .ant-modal-close-icon svg {
    color: #fff;
    margin: 7px 0 0 0;
}

/* @media (max-width: 1279px) {
    .withdraw-claim-schedule-modal .ant-modal-body {
        padding: 0;
        background-color: #0d0d0f;
        border-radius: 7.5px;
        border: solid 1.5px #202429;
        max-height: 386rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }
} */

@media (max-width: 767px) {
    .withdraw-claim-schedule-modal .ant-modal-body {
        padding: 0;
        background-color: #0d0d0f;
        border-radius: 7.5px;
        border: solid 1.5px #202429;
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}