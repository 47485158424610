.noItems {
  max-width: 338px;
  margin: 135px auto;
}

.noItemsHead, .noItemsText {
  color: #fff;
  text-align: center;
}

.noItemsHead {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.63px;
}

.noItemsText {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.38px;
}