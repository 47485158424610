.transaction-loading .ant-modal {
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
    background-color: #0d0d0f;
    padding-bottom: 0;
}

.transaction-loading .ant-modal-body {
    background-color: #0d0d0f;
    border: none;
    border-radius: 7.5rem;
}

.transaction-loading .ant-modal-header {
    background-color: #0d0d0f !important;
    background: url('../../assets/img/middle-title.png') no-repeat center / cover;
    background-size: 131rem;
    height: 96rem;
    /* background-position: 50%; */
    padding-bottom: 0;
    padding-top: 1rem;
}

.transaction-loading .ant-modal-header .ant-modal-title {
    font-family: Poppins;
    font-size: 22.5rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    padding-top: 48rem;
    z-index: 1000;
    margin-bottom: 0;
}

.transaction-loading .ant-modal-body {
    padding: 0 22.5rem 0;
}

.transaction-loading .ant-modal-body .body-button {
    padding: 0 15px 20px 15px;
}

.transaction-loading .ant-modal-content .ant-modal-close-icon svg {
    display: none;
}

.transaction-loading .ant-modal-content .ant-modal-close {
    padding: 15rem;
}

.transaction-loading .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
    width: 22rem;
    height: 22.5rem;
    position: absolute;
    right: 7rem;
}

.transaction-loading .ant-modal-content {
    background-color: #0d0d0f !important;
}

/* @media (min-width: 1281px) {
    .transaction-loading .ant-modal {
        width: 320rem !important;
    }

    .transaction-loading .ant-modal-header .ant-modal-title {
        font-size: 22.5rem;
    }

    .transaction-loading .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
        background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
        width: 22rem;
        height: 20rem;
        max-width: 22px;
        max-height: 22px;
    }
}

@media (max-width: 1280px) {
    .transaction-loading .ant-modal-header .ant-modal-title {
        font-size: 22.5rem;
    }

    .transaction-loading .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
        background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
        width: 22rem;
        height: 22rem;
        max-width: 22px;
        max-width: 22.5px;
    }
} */

@media (max-width: 767px) {
    .transaction-loading .ant-modal {
        border-radius: 15rem;
        border: solid 3rem #202429;
    }

    .transaction-loading .ant-modal-body {
        padding: 0 60rem 0;
    }

    .transaction-loading .ant-modal-content .ant-modal-close {
        padding: 50rem 15rem;
    }

    .transaction-loading .ant-modal-header .ant-modal-title {
        padding-top: 105rem;
        font-size: 65rem;
    }

    .transaction-loading .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
        background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
        width: 75rem;
        height: 75rem;
        right: 15rem;
        max-width: 21px;
        max-height: 21px;
    }

    .transaction-loading .ant-modal-header {
        background-color: #0d0d0f !important;
        background: url('../../assets/img/middle-title.png') no-repeat center / cover;
        background-size: 320rem;
        height: 220rem;
        padding-bottom: 0;
        padding-top: 1rem;
    }
}