/* UNSTAKE */

.not-enough-tokens-modal .ant-modal-title {
    font-size: 22.5rem;
    font-weight: bold;
    text-align: left;
    color: #fff;
}

.not-enough-tokens-modal .ant-modal {
    padding-bottom: 0;
}
.not-enough-tokens-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
}

.not-enough-tokens-modal .ant-modal-header {
    background: url('../../assets/img/headerBackground.png') no-repeat;
    background-size: 120rem;
    height: 62.5rem;
    display: flex;
    align-items: center;
}

.not-enough-tokens-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    width: 56rem;
    height: 56rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.not-enough-tokens-modal .ant-modal-body .body-description {
    padding: 20rem 25rem 0;
}

.not-enough-tokens-modal .ant-modal-body .body-button {
    padding: 0 15rem 20rem 15rem;
}

.not-enough-tokens-modal .ant-modal-content .ant-modal-close-icon svg {
    color: #af0000;
    width: 19rem;
    height: 19.5rem;
}

@media (max-width: 767px) {
    .not-enough-tokens-modal .ant-modal-title {
        font-size: calc(25rem * 2);
        font-weight: bold;
        text-align: left;
        color: #fff;
    }

    .not-enough-tokens-modal .ant-modal-header {
        background-size: 240rem;
        height: 130rem;
    }

    .not-enough-tokens-modal .ant-modal-body {
        border-radius: 20rem;
        border: solid 3rem #202429;
    }

    .not-enough-tokens-modal .ant-modal-body .body-description {
        padding: 40rem 50rem 0;
    }

    .not-enough-tokens-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
        width: calc(60rem * 2);
        height: calc(60rem * 2);
    }

    .not-enough-tokens-modal .ant-modal-content .ant-modal-close-icon svg {
        width: calc(30rem * 2);
        height: calc(30rem * 2);
    }
}
