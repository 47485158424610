
.stake-project-modal .ant-modal {
    padding-bottom: 0;
}

.stake-project-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5rem;
    border: solid 1.5rem #202429;
}

.stake-project-modal .ant-modal-body .body-description {
    padding: 0 28rem;
}

.stake-project-modal .ant-modal-body .body-button {
    padding: 13rem 22rem 20rem 22rem;
}

.stake-project-modal .ant-modal-content .ant-modal-close {
    top: -30px;
    right: -30px;
}


.stake-project-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../assets/svg/closeButton.svg') no-repeat center / cover;
    width: 30px;
    height: 30px;
}

.stake-project-modal .ant-modal-content .ant-modal-close-icon svg {
    color: #fff;
    margin: 7px 0 0 0;
}

/* UNSTAKE */
.unstake-project-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5px;
    border: solid 1.5px #202429;
}

.unstake-project-modal .ant-modal-header {
    background: url('../../assets/img/headerBackground.png') no-repeat;
    background-size: 120px;
}

.unstake-project-modal .ant-modal-body .body-description {
    padding: 20px 20px 0 20px;
}

.unstake-project-modal .ant-modal-body .body-button {
    padding: 13rem 28rem 20rem 28rem;
}

.unstake-project-modal .ant-modal-content .ant-modal-close-icon svg {
    color: #af0000;
    width: 19px;
    height: 19.5px;
}


/* @media screen and (max-width: 1280px) {
    .stake-project-modal .ant-modal-body .body-button {
        padding: 0 28.5rem 20rem 28.5rem;
    }

    .unstake-project-modal .ant-modal-body .body-button {
        padding: 0 28.5rem 20rem 28.5rem;
    }
} */

@media screen and (max-width: 767px) {
    .stake-project-modal .ant-modal-body .body-button {
        padding: 0 66rem 0rem;
    }

    .stake-project-modal .ant-modal-body .body-description {
        padding: 0 82rem 40rem;
    }

    .unstake-project-modal .ant-modal-body .body-button {
        padding: 0 66rem 0rem;
    }
}