
.staking-infomation-modal .ant-modal {
    padding-bottom: 0;
}

.staking-infomation-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5px;
    border: solid 1.5px #202429;
}

.staking-infomation-modal .ant-modal-body .body-description{
    padding: 17.5px 31.5px 21px 28.5px;
}

.staking-infomation-modal .ant-modal-body .body-button{
    padding: 0 15px 20px 15px;
}

.staking-infomation-modal .ant-modal-content .ant-modal-close {
    top: -30px;
    right: -30px;
}


.staking-infomation-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon{
    background: url('../../assets/svg/closeButton.svg') no-repeat center / cover;
    width: 30px;
    height: 30px;
}

.staking-infomation-modal .ant-modal-content .ant-modal-close-icon svg{
    color: #fff;
    margin: 7px 0 0 0;
}