@import '~antd/dist/antd.less';

html {
  font-size: (1/1280) * 100vw;
}

.ant-modal-mask {
  z-index: 999;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes opacityPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes nextAnimate {
  from {
    right: 31.5%;
  }

  to {
    right: 31%;
  }
}

@keyframes backAnimate {
  from {
    left: 31.5%;
  }

  to {
    left: 31%;
  }
}

@keyframes nextAnimateMobile {
  from {
    right: 10.5%;
  }

  to {
    right: 9%;
  }
}

@keyframes backAnimateMobile {
  from {
    left: 10.5%;
  }

  to {
    left: 9%;
  }
}

@primary-color: #1DA57A;