@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./assets/fonts/Poppins-Medium.ttf) format('ttf');
}
@font-face {
  font-family: 'Poppins-Semibold';
  src: local('Poppins-Semibold'), url(./assets/fonts/Poppins-SemiBold.ttf) format('ttf');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('ttf');
}
@font-face {
  font-family: 'HalloweenNight';
  src: url(./assets/fonts/Halloween-Night.otf);
}
@font-face {
  font-family: 'HalloweenNight2';
  src: url(./assets/fonts/Halloween-Night2.otf);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-modal-mask{
  z-index: 1000;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.ant-menu-vertical {
  border-right: none;
}

/* overwrite modal */
.ant-modal {
  /* width: 400px !important; */
}
.ant-modal-footer {
  display: none;
}
.ant-modal-header {
  background-color: rgb(43, 49, 53);
  border-bottom: none;
  border-radius: 12px 12px 0px 0px;
  padding: 24px;
}
.ant-modal-title {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.ant-modal-content {
  background-color: rgb(32, 36, 41);
  border-radius: 12px;
}

.ant-modal-mask {
  background: rgba(0,0,0, 0.8);
  backdrop-filter: blur(4px);
}

/* overwrite slider */
.ant-slider-handle {
  width: 18px;
  height: 18px;
  margin-top: -7px;
  border: solid 5px #ff001c;
}

.ant-slider-track {
  background-image: linear-gradient(to top, #f00, #af0000);
}

.ant-slider:hover .ant-slider-track {
  background-color: linear-gradient(to top, #f00, #af0000);
}

.ant-slider:hover .ant-slider-handle:hover,
.ant-slider-handle:focus,
.ant-slider-handle:active {
  border: solid 5px #af0000;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #af0000;
}

.ant-modal-close {
  color: #df1e33;
  padding: 5px;
}

/* overwrite number input */

.ant-input-number-handler-wrap,
.ant-input-number-handler-wrap:hover,
.ant-input-number-handler-wrap:active,
.ant-input-number-handler-wrap:focus {
  background: #2b3135;
  border-left: unset;
}

.ant-input-number-handler:active {
  background: #2b3135;
}

.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  color: #fff;
  width: 12px;
  height: 12px;
}

/* overwrite menu */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
}

/* overwrite swiper */
.swiper {
  padding-top: 10rem;
  padding-bottom: 100rem;
}

.swiper-button-prev {
  background: url('../src/assets/left-arrow.png') no-repeat center / cover;
  width: 22px;
  height: 36px;
  top: calc(50% - 50rem);
  padding: 5rem;

  animation: backAnimate 0.32s alternate infinite;
}
.swiper-button-prev::after {
  content: '';
}

.swiper-button-next {
  background: url('../src/assets/right-arrow.png') no-repeat center / cover;
  width: 22px;
  height: 36px;
  top: calc(50% - 50rem);
  padding: 5rem;

  animation: nextAnimate 0.32s alternate infinite;
}

.swiper-button-next::after {
  content: '';
}

@media screen and (max-width: 767px) {
  .swiper-button-next {
    right: 0;
    width: 17px;
    height: 27px;
    top: 50%;

    animation: nextAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-next::after {
    content: '';
  }

  .swiper-button-prev {
    left: 0;
    width: 17px;
    height: 27px;
    top: 50%;

    animation: backAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-prev::after {
    content: '';
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .swiper-button-next {
    right: 0;
    animation: nextAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-next::after {
    content: '';
  }

  .swiper-button-prev {
    left: 0;
    animation: backAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-prev::after {
    content: '';
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .swiper-button-next {
    right: 0%;

    animation: nextAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-next::after {
    content: '';
  }

  .swiper-button-prev {
    left: 0%;
    animation: backAnimateMobile 0.32s alternate infinite;
  }

  .swiper-button-prev::after {
    content: '';
  }
}
