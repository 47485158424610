.success-mint-stake-modal .ant-modal {
  padding-bottom: 0;
}

.success-mint-stake-modal .ant-modal-header {
  background-color: #0d0d0f !important;
  background: url('../../assets/img/middle-title.png') no-repeat;
  background-size: 120px;
  background-position: 50%;
  padding-bottom: 0;
  padding-top: 1px;
}

.success-mint-stake-modal .ant-modal {
  border-radius: 7.5rem;
  border: solid 1.5rem #202429;
  padding-bottom: 0;
}

.success-mint-stake-modal .ant-modal-body {
  background-color: #0d0d0f;
  border: none;
  border-radius: 7.5px;
  padding: 0;
}


.success-mint-stake-modal .circle {
  margin: 26px 0 23px;
  display: flex;
  justify-content: center;
}

.success-mint-stake-modal .buttonsSuccess {
  margin: 0 26rem;
  display: flex;
  row-gap: 10rem;
  flex-direction: column;
}


.success-mint-stake-modal .image .loading-modal {
  background: transparent;
}

.success-mint-stake-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
  background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
  width: 22rem;
  height: 22.5rem;
  position: absolute;
  right: 7rem;
}

.success-mint-stake-modal .ant-modal-content .ant-modal-close-icon svg {
  display: none;
}

.success-mint-stake-modal .ant-modal {
  /* width: 350rem !important; */
}

/* @media screen and (max-width: 1280px) {
  .success-mint-stake-modal .ant-modal {
    width: 400rem !important;
  }

  .success-mint-stake-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
    width: 22rem;
    height: 22rem;
    z-index: 1000;
  }
} */

@media screen and (max-width: 767px) {
  .success-mint-stake-modal .ant-modal {
    border-radius: 20rem;
    border: solid 3rem #202429;
  }

  .success-mint-stake-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../assets/img/closeCircle.png') no-repeat center / cover;
    width: 75rem;
    height: 75rem;
    right: 15rem;
    max-width: 21px;
    max-height: 21px;
  }

  .success-mint-stake-modal .buttonsSuccess {
    margin: 0 90rem;
    display: flex;
    row-gap: 10rem;
    flex-direction: column;
  }
}