.rent-info-modal .ant-modal {
    width: 389rem;
}
.rent-info-modal .ant-modal-content {
    width: 389rem;
}

.rent-info-modal .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5px;
    border: solid 1.5px #202429;
    width: 389rem;

    /* @media screen and (max-width: 767px) {
        width: 700rem;
    } */
}

.rent-info-modal .ant-modal-content .ant-modal-close {
    top: -30px;
    right: -30px;
}


.rent-info-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../../assets/svg/closeButton.svg') no-repeat center / cover;
    width: 30px;
    height: 30px;
}

.rent-info-modal .ant-modal-content .ant-modal-close-icon svg {
    color: #fff;
    margin: 7px 0 0 0;
}


/* MOBILE */
.rent-info-modal-mobile .ant-modal {
    width: 880rem;
}
.rent-info-modal-mobile .ant-modal-content {
    width: 880rem;
    margin: 0 auto;
}

.rent-info-modal-mobile .ant-modal-body {
    padding: 0;
    background-color: #0d0d0f;
    border-radius: 7.5px;
    border: solid 1.5px #202429;
    width: 880rem;
}

.rent-info-modal-mobile .ant-modal-content .ant-modal-close {
    top: -30px;
    right: -30px;
}


.rent-info-modal-mobile .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
    background: url('../../../assets/svg/closeButton.svg') no-repeat center / cover;
    width: 30px;
    height: 30px;
}

.rent-info-modal-mobile .ant-modal-content .ant-modal-close-icon svg {
    color: #fff;
    margin: 7px 0 0 0;
}